import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { deepCompare } from "@website/utils";
// eslint-disable-next-line @nx/enforce-module-boundaries
import { useViewPort } from "@website/hooks";
import { BannerSlide } from "@website/widget";
import { toString } from "lodash";
import { useRouter } from "next/router";
import { FC, Fragment, memo, useCallback } from "react";
import { HotelCard } from "../hotel-card";
import { HotelSnappPay } from "../hotel-snapp-pay";
import ErrorArt from "./assets/error-art.png";
import NotFoundArt from "./assets/not-found-art.png";
import { useHotelCitiesMainContent } from "./hotel-cities-main-content.hook";
import { HotelCitiesMainContentPropertiesType } from "./hotel-cities-main-content.types";
import { PageMessage } from "./hotel-page-message";
import { HotelSuggestionCarousel } from "./hotel-suggestion-carousel";

const PageNotFound = ({
  cityTitle,
  hasFilters,
  onActionClick
}: {
  cityTitle: string;
  hasFilters: boolean;
  onActionClick: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <PageMessage
      actionIcon={hasFilters ? <MaterialIcon name="delete_outline" /> : null}
      actionTitle={
        hasFilters
          ? t("hotel_cities.remove_filters")
          : t("hotel_cities.change_destination")
      }
      artSrc={NotFoundArt.src}
      caption={
        hasFilters ? (
          <>{t("hotel_cities.not_found_filtered_data")}</>
        ) : (
          //TODO
          <>
            متاسفانه هتل و اقامتگاه فعالی در شهر{" "}
            <span className="text-on-surface-high-emphasis font-medium">
              &quot;{cityTitle}&quot;
            </span>{" "}
            یافت نشد. لطفا شهرهای دیگر را جستجو کنید.
          </>
        )
      }
      message={t("hotel_cities.not_found_result")}
      onActionClick={onActionClick}
    />
  );
};

const PageError = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const onActionClick = useCallback(
    () => router.push(toString(process.env.NEXT_PUBLIC_BASE_URL_B2C)),
    [router]
  );
  return (
    <PageMessage
      actionIcon={<MaterialIcon name="arrow_forward" />}
      actionTitle={t("hotel_cities.back_to_main_page")}
      artSrc={ErrorArt.src}
      caption={t("hotel_cities.not_found_data")}
      message={t("hotel_cities.error")}
      onActionClick={onActionClick}
    />
  );
};

const HotelCitiesMainContentComponent: FC<
  HotelCitiesMainContentPropertiesType
> = (properties) => {
  const {
    size,
    cityTitle,
    hotelsSuggested,
    onVisibilityChange,
    onClick,
    isPlaceholder,
    snappPayData,
    bannerSlideList,
    isBannersLoading
  } = properties;
  const {
    hotelList,
    loading,
    hasNoResults,
    hasError,
    hasSort,
    hasFilters,
    hasHotelSuggestion,
    suggestionOrder,
    handleNotFoundAction
  } = useHotelCitiesMainContent(properties);
  const { isMobile, isTablet } = useViewPort();

  return hasError ? (
    <PageError />
  ) : hasNoResults ? (
    <PageNotFound
      cityTitle={cityTitle}
      hasFilters={hasFilters}
      onActionClick={handleNotFoundAction}
    />
  ) : (
    <section
      className={`${
        size === "small"
          ? "grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3"
          : "flex w-full flex-col items-center gap-4 lg:gap-6"
      }`}
    >
      {hasHotelSuggestion &&
        !hasFilters &&
        !hasSort &&
        hotelList?.length > 0 && (
          <section
            className={`col-span-full w-full ${
              suggestionOrder === 1
                ? "order-1"
                : suggestionOrder === 2
                  ? "order-2"
                  : suggestionOrder === 3
                    ? "order-3"
                    : ""
            } ${size === "small" ? "sm:order-2 lg:order-3" : ""}`}
          >
            <HotelSuggestionCarousel hotelsSuggested={hotelsSuggested} />
          </section>
        )}
      <HotelSnappPay isEligible={snappPayData?.isEligible} />
      {hotelList?.map((hotelInfo, index) => (
        <Fragment key={hotelInfo.id}>
          {(bannerSlideList?.length ?? 0) > 0 && index === 1 && (
            <div
              className={
                process.env.NEXT_PUBLIC_APP_NAME === "villa"
                  ? "-order-1 col-span-1 gap-4 sm:col-span-2 lg:col-span-3"
                  : ""
              }
            >
              <BannerSlide
                className="w-full !p-0"
                height={isMobile ? 87 : isTablet ? 175 : 70}
                isLoading={isBannersLoading}
                list={bannerSlideList}
                width={isMobile ? 378 : isTablet ? 760 : 812}
              />
            </div>
          )}
          <article className="w-full" style={{ order: index }}>
            <HotelCard
              hotelInfo={hotelInfo}
              index={index + 1}
              isLazyImage={index > 1}
              key={hotelInfo.hotelDetail?.hotelTitle}
              loading={isPlaceholder && loading}
              onClick={() => onClick?.(index + 1, hotelInfo)}
              onVisibilityChange={onVisibilityChange}
              size={size}
              snappPayData={snappPayData}
            />
          </article>
        </Fragment>
      ))}
    </section>
  );
};

export const HotelCitiesMainContent = memo(
  HotelCitiesMainContentComponent,
  deepCompare
);
