import { useIsVisible } from "@website/hooks";
import { isFilled } from "@website/utils";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import {
  HotelReservedCardPropertiesType,
  RoomRacksType
} from "./hotel-reserve-card.types";

export const useHotelReserveCard = (
  properties: HotelReservedCardPropertiesType
) => {
  const {
    id,
    bookNeedConfirm,
    hotelId,
    provider,
    promotion,
    bookType,
    title,
    availableRooms,
    extraBed,
    hasBreakfast,
    facilities,
    price,
    img,
    adults,
    isLoading,
    nightCount,
    minStay,
    priceOff,
    rackIncluded,
    description,
    onClick: onHandleClick,
    loadRoomRacks,
    hasMorContentButton,
    freeCancellation,
    cancellationPolicies,
    regularBadges,
    hotelType,
    roomDetail,
    onInView,
    snappPayData
  } = properties;

  const [isRoomRacksLoading, setIsRoomRackLoading] = useState(false);
  const [roomRacks, setRoomRacks] = useState<Array<RoomRacksType>>([]);
  const [openPackageModal, setOpenPackageModal] = useState(isFilled(roomRacks));

  useEffect(() => {
    setOpenPackageModal(isFilled(roomRacks));
  }, [roomRacks]);
  const onClose = useCallback(() => setOpenPackageModal(false), []);

  const handleVisibilityChange = debounce(
    (inView: boolean) => onInView?.(inView),
    1000
  );

  const { reference } = useIsVisible({
    onChange: handleVisibilityChange
  });

  const onRacksClick = useCallback(() => {
    setIsRoomRackLoading(true);
    setRoomRacks([]);
    loadRoomRacks?.({ hotelId, roomId: id })
      .then((data) => {
        setRoomRacks(data);
      })
      .finally(() => {
        setIsRoomRackLoading(false);
      });
  }, [loadRoomRacks, id, hotelId]);

  return {
    roomRacks,
    openPackageModal,
    onClose,
    hotelReserveCardContentProps: {
      id,
      title,
      availableRooms,
      extraBed,
      hasBreakfast,
      facilities,
      img,
      adults,
      onClick: roomDetail?.handleShowRoomDetail,
      description,
      hasMorContentButton,
      freeCancellation,
      cancellationPolicies,
      regularBadges,
      rackIncluded
    },
    hotelReserveCardFooterProps: {
      id,
      title,
      bookNeedConfirm,
      hotelId,
      provider,
      promotion,
      bookType,
      availableRooms,
      minStay,
      price,
      isLoading,
      onClick: onHandleClick,
      nightCount,
      priceOff,
      rackIncluded,
      roomRacks,
      isRoomRacksLoading,
      onRacksClick,
      adults,
      hotelType,
      snappPayData
    },
    hotelReserveCardFullCapacityProps: {
      id,
      onClick: roomDetail?.handleShowRoomDetail
    },
    hotelReserveCardRacksProps: {
      open: openPackageModal,
      onClose,
      roomRacks,
      title
    },
    reference
  };
};
