import { Button, LazyImage } from "@website/ui";
import { deepCompare } from "@website/utils";
import { useRouter } from "next/router";
import { memo } from "react";
import submitLogo from "../assets/submit-logo.png";

const Component = () => {
  const router = useRouter();
  return (
    <div className="md:border-outline-thin flex flex-col items-center justify-between gap-6 p-6 md:rounded-lg md:border lg:w-[460px] xl:w-[542px]">
      <LazyImage src={submitLogo?.src} />
      <div className="text-subtitle-1">نظر شما با موفقیت ثبت شد.</div>
      <div className="text-caption text-on-surface-medium-emphasis flex flex-col items-center justify-between">
        <div>
          از شما متشکریم که وقت گذاشتید تا نظرتان را با ما در میان بگذارید.
        </div>
        <div>نظر شما پس از بررسی در سایت منتشر خواهد شد.</div>
      </div>
      <Button
        className="hidden md:block"
        color="secondary"
        onClick={() => router.push("https://www.snapptrip.com/")}
        variant="outline"
      >
        رفتن به صفحه اصلی
      </Button>
      <div className="border-t-outline-thin fixed bottom-0 block w-full border-t px-6 pb-6 pt-4 md:hidden">
        <Button
          color="secondary"
          fullWidth
          onClick={() => router.push("https://www.snapptrip.com/")}
          variant="outline"
        >
          بازگشت
        </Button>
      </div>
    </div>
  );
};

export const HotelReviewSubmitVoucher = memo(Component, deepCompare);
