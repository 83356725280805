import { fetcher } from "@website/fetcher";
import { SuggestionResponseType } from "./top-hotels.types";

export const SuggestionUrl = `${process.env.NEXT_PUBLIC_BASE_URL_HAPI}/service2/hotel/v1/suggest/top-hotels`;

export const SuggestionService = (): Promise<SuggestionResponseType> =>
  fetcher({
    url: SuggestionUrl
  })
    .then((response) => response.data)
    .catch(() => []);
