import { useTranslation } from "@website/locale";
import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { HotelReserveCard } from "../../hotel-reserve-rooms-layout/hotel-reserve-card";
import { CountDownTimer } from "../count-down-timer";
import { HotelLastSecondHotelPagePropertiesTypes } from "./hotel-last-second-hotel-page.type";

const Component: FC<HotelLastSecondHotelPagePropertiesTypes> = ({
  lastSecondRooms,
  loadRoomRacks,
  hasMorContentButton,
  roomDetail,
  onInView,
  snappPayData
}) => {
  const { t } = useTranslation();

  const lastSecondRoomsData = lastSecondRooms?.map((room) => ({
    ...room,
    regularBadges:
      room?.regularBadges?.filter((badge) => badge.type !== "last_minute") || []
  }));

  return (
    <div className="bg-transparent-primary border-transparent-primary mt-12 flex flex-col gap-4 border-y p-6 lg:mt-[124px] xl:mx-6 xl:mt-[78px] xl:rounded-xl xl:border">
      <div className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between md:gap-4">
        <div className="flex flex-row items-center gap-2">
          <h2 className="text-subtitle-1 text-primary md:text-headline-6">
            {t("last_second.main_title_hotel_page")}
          </h2>
        </div>
        <CountDownTimer
          chipClassName="!h-[32px] pr-1 md:!h-[28px] md:gap-[6px]"
          className="flex gap-1 md:gap-0"
          resetOnEnd
          textWrapperClassName="text-body-1 md:text-body-2 text-on-color-high-emphasis gap-3 md:gap-1"
          wrapperClassName="md:w-[143px]"
        />
      </div>
      <div className="flex flex-col gap-6">
        {lastSecondRoomsData?.map((room) => (
          <HotelReserveCard
            {...room}
            hasMorContentButton={hasMorContentButton}
            key={room.id}
            loadRoomRacks={loadRoomRacks}
            onClick={room.onClick}
            onInView={(inView) => onInView?.(inView, room)}
            roomDetail={roomDetail}
            snappPayData={snappPayData}
          />
        ))}
      </div>
    </div>
  );
};
export const HotelLastSecondHotelPage = memo(Component, deepCompare);
