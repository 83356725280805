import { MaterialIcon } from "@website/icons";
import { Loading, PopoverFull } from "@website/ui";
import { deepCompare, isFilled } from "@website/utils";
import { ReserveCommentPopupScroll } from "@website/widget";
import { omit } from "lodash";
import { useRouter } from "next/router";
import { memo, useCallback } from "react";
import { calendar } from "../../../hotel-calendar/hotel-calendar.stories";
import { HotelReserveCardDetailFacilities } from "../hotel-reserve-card-facilities";
import { HotelReserveCardFooter } from "../hotel-reserve-card-footer";
import { HotelRoomCalendar } from "../hotel-room-calendar";
import { useHotelReserveCardDetail } from "./hotel-reserve-card-detail.hook";
import { HotelReservedCardDetailPropertiesType } from "./hotel-reserve-card-detail.types";

const Component = (properties: HotelReservedCardDetailPropertiesType) => {
  const { title, roomDetail, availableRooms } = properties;
  const {
    menuItems,
    onClick,
    isLoading,
    hotelRoomCalendar,
    hotelReserveCardDetailComments,
    hotelReserveCardFooterProperties,
    hotelReserveCardDetailFacilitiesProperties
  } = useHotelReserveCardDetail(properties);
  const router = useRouter();

  const onClose = useCallback(
    (isIcon: boolean) => {
      isIcon && roomDetail?.setShowRoomDetail(false);
      if (roomDetail?.onLoadFilter && isFilled(roomDetail?.filters)) {
        roomDetail?.onLoadFilter(roomDetail?.filters);
      }
      return router.push(
        {
          pathname: router.pathname,
          query: {
            ...omit(
              router?.query,
              "popover",
              Object.keys(router?.query).filter((item) =>
                item.startsWith("filter_")
              )
            )
          }
        },
        undefined,
        { shallow: true, scroll: false }
      );
    },
    [roomDetail, router]
  );

  return (
    <PopoverFull
      className="shadow-4 fixed right-0 h-full rounded-none md:right-auto md:w-[466px] lg:w-[480px]"
      onClose={() => onClose(false)}
      open={roomDetail?.showRoomDetail ?? false}
      position="end"
    >
      <div className="flex h-full flex-col">
        <div className="text-subtitle-1 flex flex-row items-center justify-start gap-4 px-6 py-4">
          <MaterialIcon
            className="text-on-surface-medium-emphasis scale-125 cursor-pointer"
            name="arrow_forward"
            onClick={() => onClose(true)}
          />
          <span className="truncate">{title}</span>
        </div>
        <div className="flex flex-row gap-8 border-y px-6">
          {menuItems?.map((item) => (
            <div
              className={`text-body-2 cursor-pointer whitespace-nowrap py-4 ${
                item.selected
                  ? "text-secondary border-secondary border-b-2"
                  : "text-on-surface-high-emphasis"
              }`}
              id={item.id}
              key={item.id}
              onClick={(event) => onClick(event)}
            >
              {item.title}
            </div>
          ))}
        </div>
        <div className="h-full overflow-y-auto border-b">
          {menuItems?.map((item) => {
            if (item.id === "facilities" && item.selected)
              return (
                <HotelReserveCardDetailFacilities
                  {...hotelReserveCardDetailFacilitiesProperties}
                />
              );
            if (item.id === "roomCalendar" && item.selected)
              return isLoading ? (
                <div className="flex h-full items-center justify-center">
                  <Loading />
                </div>
              ) : (
                isFilled(calendar) && (
                  <HotelRoomCalendar {...hotelRoomCalendar} />
                )
              );
            if (item.id === "comments" && item.selected)
              return isFilled(roomDetail?.filters) &&
                router?.query["filter_attitude"] &&
                !isFilled(roomDetail?.comments) ? (
                <ReserveCommentPopupScroll
                  {...hotelReserveCardDetailComments}
                  className="h-full"
                />
              ) : isFilled(roomDetail?.comments) ? (
                <ReserveCommentPopupScroll
                  {...hotelReserveCardDetailComments}
                  className="h-full"
                />
              ) : null;
            return null;
          })}
        </div>
        <div className="mt-auto">
          {availableRooms && (
            <HotelReserveCardFooter {...hotelReserveCardFooterProperties} />
          )}
        </div>
      </div>
    </PopoverFull>
  );
};
export const HotelReserveCardDetail = memo(Component, deepCompare);
