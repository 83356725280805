import { useJwtToken, useViewPort } from "@website/hooks";
import { useCallback, useEffect, useState } from "react";

const UseReverseBookingModal = () => {
  const { isDesktop } = useViewPort();
  const { phoneNumber } = useJwtToken();
  const [isReservePageOpen, setIsReservePageOpen] = useState(false);

  const onModalClose = useCallback(() => setIsReservePageOpen(false), []);
  const onModalOpen = useCallback(() => {
    if (!phoneNumber) {
      // return openLoginModal(router).then(() => setIsReservePageOpen(true));
    }
    setIsReservePageOpen(true);
  }, [phoneNumber]);

  useEffect(() => {
    setIsReservePageOpen(false);
  }, [isDesktop]);
  return {
    isReservePageOpen,
    onModalClose,
    onModalOpen
  };
};

export default UseReverseBookingModal;
