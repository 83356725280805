import { fetcher } from "@website/fetcher";
import { PopularCitiesResponseType } from "./types";

export const PopularCitiesUrl = `${
  process.env.NEXT_PUBLIC_BASE_URL_CDN || "https://cdnsnapptrip.com"
}/shared/top-cities/top-cities.json`;

export const PopularCitiesService = (): Promise<PopularCitiesResponseType> =>
  fetcher({
    url: PopularCitiesUrl
  })
    .then((response) => response.data)
    .catch(() => []);
