import { LazyImage, Skeleton } from "@website/ui";
import { deepCompare, numberConvert, numberSeparator } from "@website/utils";
import { useRouter } from "next/router";
import { memo } from "react";
import snapppayLogo from "../assets/snapppay-logo.svg";
import { hotelSnappayBadgePropertiesType } from "./hotel-snapp-pay-badge.types";
const Component = ({
  isEligible,
  showDownPayment,
  loading,
  markup,
  price,
  page = "cityPage"
}: hotelSnappayBadgePropertiesType) => {
  const router = useRouter();

  return isEligible && showDownPayment ? (
    <div
      className={`${
        page === "cityPage"
          ? "h-[36px] w-full md:h-[32px] md:rounded-lg"
          : "h-[32px] lg:max-w-[275px] lg:rounded-lg"
      } bg-transparent-secondary text-ventures-fin-tech text-caption flex flex-row justify-between px-4 py-2`}
    >
      <div className="flex flex-row gap-2">
        <div className="bg-ventures-fin-tech size-[16px] rounded-sm py-1.5 text-center">
          <LazyImage src={snapppayLogo?.src} />
        </div>
        <div> رزرو با قسط اول از</div>
      </div>
      <div className="flex w-1/2 flex-row justify-end gap-1">
        {loading ? (
          <Skeleton height={16} shape="rectangle" />
        ) : (
          <span>
            {markup &&
              price &&
              numberConvert(numberSeparator((markup * price) / 4), {
                locale: router?.locale
              })}
          </span>
        )}
        <span>تومان</span>
      </div>
    </div>
  ) : null;
};
export const HotelSnappPayBadge = memo(Component, deepCompare);
