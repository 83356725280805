import { useTranslation } from "@website/locale";
import { isFilled } from "@website/utils";
import { useRouter } from "next/router";
import {
  BaseSyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useHotelReserveCard } from "../hotel-reserve-card.hook";
import { HotelReservedCardDetailPropertiesType } from "./hotel-reserve-card-detail.types";
export const useHotelReserveCardDetail = (
  properties: HotelReservedCardDetailPropertiesType
) => {
  const { facilities, roomDetail } = properties;
  const { hotelReserveCardFooterProps } = useHotelReserveCard(properties);
  const selectedTab = useRef(roomDetail?.tabSelected);
  const { t } = useTranslation();
  const router = useRouter();

  const defaultMenuItems = useMemo(
    () => [
      ...(isFilled(facilities)
        ? [
            {
              title: t("global.facilities"),
              id: "facilities",
              selected: selectedTab.current === "facilities"
            }
          ]
        : []),
      {
        title: t("global.room_calendar"),
        id: "roomCalendar",
        selected: selectedTab.current === "roomCalendar"
      },
      {
        ...(isFilled(roomDetail?.filters) &&
        router?.query["filter_attitude"] &&
        !isFilled(roomDetail?.comments)
          ? {
              title: t("global.comments"),
              id: "comments",
              selected: selectedTab.current === "comments"
            }
          : isFilled(roomDetail?.comments)
            ? {
                title: t("global.comments"),
                id: "comments",
                selected: selectedTab.current === "comments"
              }
            : {})
      }
    ],
    [facilities, roomDetail?.comments, roomDetail?.filters, router?.query, t]
  );

  const [menuItems, setMenuItems] = useState(defaultMenuItems);

  useEffect(() => {
    setMenuItems(defaultMenuItems);
  }, [defaultMenuItems, roomDetail?.showRoomDetail, selectedTab]);

  useEffect(() => {
    selectedTab.current = roomDetail?.tabSelected;
  }, [roomDetail?.showRoomDetail, roomDetail?.tabSelected]);

  const menuItemClick = useCallback((event: BaseSyntheticEvent) => {
    setMenuItems((previousState) =>
      previousState.map((value) => {
        if (value.id === event.target.id)
          return {
            ...value,
            selected: true
          };
        return {
          ...value,
          selected: false
        };
      })
    );
    selectedTab.current = event.target?.id;
  }, []);

  return {
    menuItems,
    onClick: menuItemClick,
    isLoading: roomDetail?.isCalendarLoading,
    hotelRoomCalendar: {
      calendar: roomDetail?.calendar ?? [],
      view: "mobile" as const
    },
    hotelReserveCardDetailComments: {
      comments: roomDetail?.comments ?? [],
      filters: roomDetail?.filters,
      onLoadFilter: roomDetail?.onLoadFilter,
      onLoadMore: roomDetail?.onLoadMoreComments,
      isLoading: roomDetail?.isCommentsLoading,
      isInsideDetail: true
    },
    hotelReserveCardFooterProperties: {
      ...hotelReserveCardFooterProps,
      view: "mobile" as const
    },
    hotelReserveCardDetailFacilitiesProperties: {
      facilities
    }
  };
};
