import { useTranslation } from "@website/locale";
import { Button, Loading } from "@website/ui";
import { deepCompare } from "@website/utils";
import { memo, useCallback, useEffect, useState } from "react";
import { HotelLastSecondHotelPage } from "../hotel-last-second";
import { HotelSearch } from "../hotel-search";
import { HotelReserveCard } from "./hotel-reserve-card";
import { HotelReserveCardSkeleton } from "./hotel-reserve-card-skeleton";
import { HotelReserveCardDetail } from "./hotel-reserve-card/hotel-reserve-card-detail";
import {
  HotelReserveRoomsLayoutPropertiesType,
  HotelReservedCardType
} from "./hotel-reserve-room-layout.types";

const Component = (properties: HotelReserveRoomsLayoutPropertiesType) => {
  const {
    hotelRooms,
    searchProperties,
    onClick,
    loadRoomRacks,
    loading,
    hasMorContentButton,
    hotelType,
    isHotelActive,
    roomDetail,
    onInView,
    onShowUnavailableRooms,
    snappPayData
  } = properties;
  const [isShowButton, setIsShowButton] = useState(true);
  const handleClick = useCallback(() => {
    setIsShowButton(false);
    onShowUnavailableRooms?.();
  }, [onShowUnavailableRooms]);
  const { t } = useTranslation();
  const lastSecondRooms = hotelRooms?.filter(
    (room) =>
      isHotelActive &&
      (room?.availableRooms || room?.rackIncluded) &&
      room?.regularBadges?.some?.((badge) => badge.type === "last_minute")
  );

  const availableRooms = hotelRooms?.filter(
    (room) =>
      isHotelActive &&
      (room?.availableRooms || room?.rackIncluded) &&
      !room?.regularBadges?.some?.((badge) => badge.type === "last_minute")
  );

  const unavailableRooms = hotelRooms?.filter(
    (room) => !isHotelActive || (!room?.availableRooms && !room?.rackIncluded)
  );

  useEffect(() => {
    setIsShowButton(
      unavailableRooms?.length > 0 &&
        (lastSecondRooms?.length > 0 || availableRooms?.length > 0)
    );
  }, [
    availableRooms?.length,
    lastSecondRooms?.length,
    unavailableRooms?.length
  ]);

  return (
    <div
      className="bg-dim-background mx-[-24px] flex min-h-[304px] flex-col xl:mx-0 xl:min-h-[264px] xl:max-w-[1200px] xl:rounded-2xl"
      id="hotel_rooms"
    >
      <div className="p-6 pb-0 xl:p-12 xl:pb-0">
        <h2 className="text-subtitle-1 md:text-headline-6 pb-2">
          {t("global.rooms")}
        </h2>
        <div className="text-on-surface-medium-emphasis text-caption pb-4">
          {t("hotel.room.search_text")}
        </div>
        <div>
          {isHotelActive === true && loading ? (
            <div className="flex justify-center">
              <Loading color="primary" size="lg" />
            </div>
          ) : (
            <HotelSearch {...searchProperties} searchType="hotel" />
          )}
        </div>
      </div>
      {lastSecondRooms?.length ? (
        <HotelLastSecondHotelPage
          hasMorContentButton={hasMorContentButton}
          lastSecondRooms={lastSecondRooms}
          loadRoomRacks={loadRoomRacks}
          onInView={onInView}
          roomDetail={roomDetail}
          snappPayData={snappPayData}
        />
      ) : null}
      <div className="p-6 pt-0 xl:p-12 xl:pt-0">
        {((!loading && !hotelRooms?.length) || isHotelActive === false) && (
          <div className="pt-6 lg:pt-[120px] xl:pt-[80px]">
            <div className="flex flex-col gap-6">
              <span className="block text-center">
                {isHotelActive === false
                  ? t("hotel.room.is_not_active")
                  : t("hotel.room.not_found_text")}
              </span>
            </div>
          </div>
        )}
        {loading ? (
          <div className="flex flex-col gap-6 pt-6 lg:pt-[120px] xl:pt-[80px]">
            <HotelReserveCardSkeleton />
            <HotelReserveCardSkeleton />
            <HotelReserveCardSkeleton />
          </div>
        ) : (
          (availableRooms?.length ?? 0) > 0 && (
            <div className="pt-6 lg:pt-[120px] xl:pt-[80px]">
              <div className="text-subtitle-1 md:text-headline-6 pb-4">
                {t("hotel.room.available_rooms")}
              </div>
              <div className="flex flex-col gap-6">
                {availableRooms.map((room) => (
                  <HotelReserveCard
                    key={room.id}
                    onClick={onClick}
                    {...room}
                    hasMorContentButton={hasMorContentButton}
                    hotelType={hotelType}
                    loadRoomRacks={loadRoomRacks}
                    onInView={(inView) => onInView?.(inView, room)}
                    roomDetail={roomDetail}
                    snappPayData={snappPayData}
                  />
                ))}
              </div>
            </div>
          )
        )}
        {!isShowButton && unavailableRooms.length > 0 && (
          <div className="pt-[56px]">
            <div className="text-subtitle-1 md:text-headline-6 pb-4">
              {t("hotel.room.not_available_rooms")}
            </div>
            <div className="flex flex-col gap-6">
              {unavailableRooms.map((room) => (
                <HotelReserveCard
                  key={room.id}
                  {...room}
                  hasMorContentButton={hasMorContentButton}
                  hotelType={hotelType}
                  loadRoomRacks={loadRoomRacks}
                  onInView={(inView) => onInView?.(inView, room)}
                  roomDetail={roomDetail}
                  snappPayData={snappPayData}
                />
              ))}
            </div>
          </div>
        )}
        {(unavailableRooms?.length ?? 0) > 0 && isShowButton && (
          <Button
            className="mt-[40px] w-full md:mt-[56px]"
            color="secondary"
            onClick={handleClick}
            variant="round-outline"
          >
            {t("hotel.room.view_all_not_available_rooms")}
          </Button>
        )}
      </div>
      <HotelReserveCardDetail
        {...((lastSecondRooms.length > 0
          ? lastSecondRooms
          : availableRooms
        )?.find(
          (room) => room.id === roomDetail?.roomId
        ) as HotelReservedCardType)}
        roomDetail={roomDetail}
      />
    </div>
  );
};

export const HotelReserveRoomsLayout = memo(Component, deepCompare);
