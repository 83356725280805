import { MiniChips } from "@website/ui";
import { deepCompare, numberConvert } from "@website/utils";
import { useRouter } from "next/router";
import { FC, memo } from "react";
import { HotelSnappPayBadge } from "../../hotel-snapp-pay";
import { HotelCardStars } from "../hotel-card-stars";
import { HotelCardInfoOptions } from "./hotel-card-info-options";
import { HotelCardInfoPropertiesType } from "./hotel-card-info.types";
import { HotelCardRating } from "./hotel-card-rating";

const Component: FC<HotelCardInfoPropertiesType> = (properties) => {
  const router = useRouter();
  const {
    hotelTitle,
    stars,
    rating,
    reviewCount,
    address,
    roomTitle,
    roomCount,
    regularBadges,
    advertisementBadge,
    size = "responsive",
    isFomo,
    snappPayData,
    loading,
    pricing
  } = properties;

  const TitleComponent = size === "small" ? "span" : "h3";
  return (
    <div
      className={`flex min-w-0 shrink grow flex-col ${
        size === "xSmall"
          ? ""
          : size === "small"
            ? "gap-3 px-4 py-3"
            : "gap-3 px-4 py-3 lg:py-4"
      } `}
    >
      <div
        className={`flex flex-col ${size === "xSmall" ? "gap-0.5" : "gap-0.5"}`}
      >
        <div className="flex flex-row items-center gap-1">
          {advertisementBadge?.text && (
            <MiniChips
              color="ads"
              size="small"
              text={advertisementBadge?.text}
              variant="contained"
            />
          )}
          <TitleComponent
            className={`text-subtitle-2 text-on-surface-high-emphasis truncate ${
              size === "xSmall" ? "text-xs" : ""
            }`}
            data-testid="hotel_card_title"
            itemProp="name"
          >
            {numberConvert(hotelTitle, { locale: router.locale })}
          </TitleComponent>
        </div>
        <HotelCardStars size={size} stars={stars} />
        {size === "xSmall" && (
          <HotelCardRating
            rating={rating}
            reviewCount={reviewCount}
            size={size}
          />
        )}
      </div>
      {size !== "xSmall" && (
        <HotelCardRating
          rating={rating}
          reviewCount={reviewCount}
          size={size}
        />
      )}
      <div
        className={
          size === "small"
            ? "flex flex-col gap-2"
            : "flex flex-col gap-2 lg:gap-1"
        }
      >
        <HotelCardInfoOptions
          address={address}
          isFomo={isFomo}
          regularBadges={regularBadges}
          roomCount={roomCount}
          roomTitle={roomTitle}
          size={size}
        />
      </div>
      {size !== "small" && size !== "xSmall" && (
        <div className="mt-auto hidden md:block">
          <HotelSnappPayBadge
            {...snappPayData}
            loading={loading}
            page="cityPage"
            price={pricing?.priceOff || pricing?.price}
          />
        </div>
      )}
    </div>
  );
};

export const HotelCardInfo = memo(Component, deepCompare);
