import Approve from "./assets/after-payment-approved.png";
import Failed from "./assets/after-payment-failed.png";
import Pending from "./assets/after-payment-pending.png";

const paymentStatusConstant = {
  approved: {
    src: Approve.src,
    alt: "approved payment",
    title: "پرداخت و رزرو موفق",
    description: `
    رزرو شما با موفقیت انجام شد.
    `
  },
  failed: {
    src: Failed.src,
    alt: "failed payment",
    title: "پرداخت ناموفق",
    description: `
    متاسفانه پرداخت شما انجام نشد. لطفا دوباره تلاش کنید.
    <br />
    اگر هزینه از کارت شما کسر شده است، مطابق قوانین بانکی کشور طی ۷۲ ساعت
    آینده به کارت شما بازگشت داده خواهد شد.
    `
  },
  payment_verified: {
    src: Approve.src,
    alt: "approved payment",
    title: "رزرو موفق",
    description: `رزرو شما با موفقیت ثبت شد.`
  },
  availability_rejected: {
    src: Failed.src,
    alt: "rejected payment",
    title: "رزرو ناموفق",
    description: `متاسفانه رزرو شما توسط هتل رد شد و ما برای جبران، یک کد تخفیف رزرو هتل برای شما پیامک خواهیم کرد. <br/>
      <br/>
      هزینه کسر شده بابت رزرو، میانگین طی 15 دقیقه آینده به کارت شما واریز میشود. 
      `
  },
  availability_pending: {
    src: Pending.src,
    alt: "approved payment",
    title: "پرداخت موفق",
    description: `
    ما در حال بررسی ظرفیت و هماهنگی با هتل هستیم.
    <br/>
    به محض نهایی شدن، نتیجه از طریق پیامک به شما اطلاع داده می‌شود.`
  },
  pending: {
    src: Approve.src,
    alt: "approved payment",
    title: "پرداخت موفق؛ در حال بررسی وضعیت",
    description: `
    رزرو شما هنوز نهایی نشده است.
    <br/>
    به محض تکمیل بررسی ظرفیت، نتیجه از طریق پیامک و ایمیل اطلاع داده می‌شود.`
  }
};

export { paymentStatusConstant };
